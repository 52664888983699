import NavBar from '../../components/navBar/NavBar';
import './postuler.css';

import imgLogo from '../../style/mesimages/logo.png';
import { useState } from 'react';

import { IoDocumentTextOutline } from "react-icons/io5";
import { IoIosTimer } from "react-icons/io";
import { IoLocationOutline } from "react-icons/io5";
import { LiaIndustrySolid } from "react-icons/lia";
import { MdOutlineWorkOutline } from "react-icons/md";
import { RiGraduationCapLine } from "react-icons/ri";

const Postuler = () => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const [printLoader, setPrintLoader] = useState(false);  // Loader state

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: "",
        cv: null,
    });

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === "cv") {
            setFormData({ ...formData, cv: files[0] });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const fileToBase64 = (file, callback) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = () => {
                const base64String = reader.result.split(",")[1];
                resolve(base64String);
            };

            reader.onerror = error => reject(error);

            reader.readAsDataURL(file);
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Set the loader to true while submitting
        setPrintLoader(true);

        // Création de l'objet de données à envoyer
        const formDataObject = {
            nomComplet: formData.name,
            email: formData.email,
            message: formData.message,
            cv: await fileToBase64(formData.cv),
        };

        try {
            const response = await fetch(`${apiUrl}/postuler`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formDataObject),
            });

            if (response.ok) {
                alert("Candidature envoyée avec succès !");
                setFormData({
                    name: "",
                    email: "",
                    message: "",
                    cv: null,
                });
            } else {
                alert("Une erreur est survenue lors de l'envoi de la candidature.");
            }
        } catch (error) {
            console.error("Erreur lors de l'envoi de la candidature :", error);
            alert("Une erreur est survenue. Veuillez réessayer.");
        }

        // Reset loader state after the response is received
        setPrintLoader(false);
    };

    return (
        <div className='recrutement'>
            <NavBar />

            {/* Loader Section */}
            {printLoader && (
                <div className="loader-overlay">
                    <div className="loader"></div>
                </div>
            )}

            <div className="job-post">
                <div className="job-header">
                    <p className="publish-date">PUBLIÉ LE 14 JAN. 2025</p>
                    <h3 className="sector">Industrie</h3>
                    <h1 className="job-title">Electrotechnicien F/H</h1>
                    <a href="#contact-form" className="apply-button">
                        POSTULER À CETTE OFFRE →
                    </a>
                </div>
                <div className="job-details">
                    <ul>
                        <li>
                            <div className='icon'>
                                <IoDocumentTextOutline size={20} />
                            </div>
                            CDI
                        </li>
                        <li>
                            <div className='icon'>
                                <IoIosTimer size={20} />
                            </div>
                            39h
                        </li>
                        <li>
                            <div className='icon'>
                                <IoLocationOutline size={20} />
                            </div>
                            Nord de Nancy, France
                        </li>
                        <li>
                            <div className='icon'>
                                <LiaIndustrySolid size={20} />
                            </div>
                            Industrie
                        </li>
                        <li>
                            <div className='icon'>
                                <MdOutlineWorkOutline size={20} />
                            </div>
                            Plus de 5 ans
                        </li>
                        <li>
                            <div className='icon'>
                                <RiGraduationCapLine size={20} />
                            </div>
                            CAP/BEP à BAC+2 en Electrotechnique
                        </li>
                    </ul>
                </div>
            </div>

            <div className="contact-form" id="contact-form">
                <h2>Candidature spontanée</h2>
                <form id="application-form" onSubmit={handleSubmit} encType="multipart/form-data">
                    <div className="form-group">
                        <label htmlFor="name">Nom complet</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            placeholder="Entrez votre nom complet"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Entrez votre email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="message">Message</label>
                        <textarea
                            id="message"
                            name="message"
                            placeholder="Ajoutez un message pour accompagner votre candidature"
                            value={formData.message}
                            onChange={handleChange}
                            required
                        ></textarea>
                    </div>
                    <div className="form-group">
                        <label htmlFor="cv">Télécharger votre CV</label>
                        <input
                            type="file"
                            id="cv"
                            name="cv"
                            accept=".pdf,.doc,.docx"
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <button type="submit">
                        Envoyer ma candidature
                    </button>
                </form>
            </div>
        </div>
    );
}

export default Postuler;
