import './popup.css';

const Popup = ({ onClose }) => {
    return (
        <div className="popup" onClick={onClose}>
            <div className="popup-inner" onClick={(e) => e.stopPropagation()}>
                <button onClick={onClose}>X</button>
                <h1>Nous recrutons !</h1>
                <p>Rejoignez notre équipe et faites partie de notre aventure passionnante.</p>
                <a href="/postuler">Postuler</a>
            </div>
        </div>
    );
};

export default Popup;
